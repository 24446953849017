<template>
	<w-layout column fill-height>
		<w-flex>
			<v-toolbar dense>
				<w-spacer />
				<w-search-input v-model="filters.search" />
				<w-btn flat icon="fas fa-filter" mini small @click="showMenu">{{ $t('actions.filter') }}</w-btn>
				<v-menu v-if="showArchivedCustomersSwitch" v-model="displayMenu" :position-x="x" :position-y="y" absolute offset-y :close-on-content-click="false">
					<w-list dense style="height: 65px">
						<w-list-tile>
							<w-list-tile-content style="min-height: 65px">
								<w-switch v-model="filters.withDeactivated" :label="$t('customers.archived_customers')" :ripple="false" />
							</w-list-tile-content>
						</w-list-tile>
					</w-list>
				</v-menu>
				<w-divider v-if="$vuetify.breakpoint.mdAndUp" vertical />
				<w-btn v-if="$vuetify.breakpoint.mdAndUp" flat :icon="detailedMode ? 'zoom_out' : 'zoom_in'" mini @click="detailedMode = !detailedMode">
					{{ detailedMode ? $t('simplified_view') : $t('detailed_view') }}
				</w-btn>
				<w-divider v-if="$vuetify.breakpoint.smAndUp" vertical />
				<w-flex shrink>
                    <w-btn icon="add" :mini="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" @click="displayCustomerCreationDialog = true">{{ $t('holding.customers.create_a_customer') }}</w-btn>
				</w-flex>
				<w-spacer />
				<CustomerCreationDialog
						v-if="accountingFirmId"
						v-model="displayCustomerCreationDialog"
						:accounting-firm-id="accountingFirmId"
						:service="service"
				/>
			</v-toolbar>
		</w-flex>
		<w-flex fill-height scroll-y>
			<w-layout justify-center row wrap>
				<w-flex v-if="$vuetify.breakpoint.smAndUp" mx-4 my-3>
					<CustomersListDetailed v-if="detailedMode && $vuetify.breakpoint.mdAndUp" :loading="loading" :value="companies" />
					<CustomersListSimple v-else :loading="loading" :value="companies" />
				</w-flex>
				<w-flex v-else>
					<w-list two-line>
						<template v-for="company in companies">
							<w-divider :key="`start-divider-${company.id}`" />
							<w-list-tile :key="company.id" avatar @click="openCompanyDetails(company)">
								<w-list-tile-avatar>
									<v-avatar color="primary" size="2.5em">
										<w-flex white--text>{{ company.abbreviation }}</w-flex>
									</v-avatar>
								</w-list-tile-avatar>
								<w-list-tile-content>
									<w-list-tile-title :class="company.deleted_at ? 'crossed--text red--text' : ''" v-text="company.company" />
									<w-list-tile-sub-title :class="company.deleted_at ? 'crossed--text red--text' : ''" v-text="company.siret" />
								</w-list-tile-content>
							</w-list-tile>
							<w-divider :key="`end-divider-${company.id}`" />
						</template>
					</w-list>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CustomersList',
	components: {
		CustomerCreationDialog: () => ({
			component: import('@/components/CustomersManager/CustomerCreationDialog')
		}),
		CustomersListSimple: () => ({
			component: import('@/components/CustomersManager/CustomersListSimple')
		}),
		CustomersListDetailed: () => ({
			component: import('@/components/CustomersManager/CustomersListDetailed')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	data: function () {
		return {
			addCustomer: false,
			archivedCustomers: [],
			companiesList: [],
			detailedMode: false,
			displayCustomerCreationDialog: false,
			filters: {
				search: '',
				withDeactivated: false
			},
			loading: false,
			showArchivedCustomersSwitch: false,
			displayMenu: false,
			x: 0,
			y: 0,
			timeout: null
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected
		}),
		companies: function () {
			return this.companiesList.map(company => {
				if (company.pivot && company.pivot.client_code) {
					company.client_code = company.pivot.client_code
				}
				return company
			})
		}
	},
	watch: {
		accountingFirm: {
			handler: function (val) {
				if (val) {
					this.getCustomers()
				}
			},
			immediate: true
		},
		filters: {
			deep: true,
			handler: 'onFiltersModified'
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.CUSTOMER_CREATED, action: this.onCustomerCreated },
				{ event: this.events.CUSTOMER_RESTORED, action: this.onCustomerRestored },
				{ event: this.events.VENDOR_DELETED, action: this.onVendorDeleted }
			]
		},
		getCustomers: function () {
			const accountingFirmId = this.accountingFirm.id
			this.companiesList = []
			this.loading = true
			this.service
				.getFilteredCustomers(accountingFirmId, this.filters)
				.then(pageData => {
					this.companiesList = pageData.customers.sort(this.appService.compareValues('company', 'asc'))
					this.showArchivedCustomersSwitch = pageData.can_see_archived_customers
				})
				.finally(() => {
					this.loading = false
				})
		},
		onCustomerCreated: function (vendor) {
			// vendor does not exist in the list then add it
			if (this.companiesList.findIndex(v => v.id === vendor.id) === -1) {
				this.companiesList.push(vendor)
			}
		},
		onCustomerRestored: function () {
			this.getCustomers()
		},
		onFiltersModified: function () {
			clearTimeout(this.timeout)
			this.timeout = setTimeout(this.getCustomers, 600)
		},
		onVendorDeleted: function (vendor) {
			return new Promise(resolve => {
				const vendorIndex = this.companiesList.findIndex(v => v.id === vendor.id)
				if (vendorIndex >= 0) {
					this.companiesList.splice(vendorIndex, 1)
				}
				return resolve()
			})
		},
		openCompanyDetails: function (company = null) {
			this.service.openCompanyDetails(company)
		},
		showMenu: function (e) {
			this.displayMenu = true
			this.x = e.clientX
			this.y = e.clientY
		}
	}
}
</script>
